<template>
  <div class="zy-multiple-pie">
    <div class="jxyh-flex-v">
      <div class="chart-pie" v-for="(item, index) in list" :key="index">
        <zy-chart-ring :color="color" :data="item.pieList">
          <div class="chart-pie-inner">
            <div>{{ item.top }}</div>
            <div style="font-size: 16px">{{ item.value }}</div>
            <div>{{ item.bottom }}</div>
          </div>
        </zy-chart-ring>
      </div>
    </div>
    <div class="jxyh-flex-v legend">
      <div class="legend-item" v-for="(item, index) in legend" :key="`tips_${index}`">
        <div :style="{ background: color[index] }"></div>
        <div>{{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import components from '@/view/jxyh/components'
export default {
  ...components(['ZyChartRing'], {}),
  props: {
    color: {
      type: Array,
      default: () => ['#FC4A66', '#007FF4', '#FFB822', '#62FF85', '#19FBFF']
    },
    list: {
      type: Array,
      default: () => [
        {
          top: '建材生产',
          value: 24808.0,
          bottom: 'tCO2',
          pieList: [
            {
              name: '机械设备',
              value: 124,
              rate: '86.65'
            },
            {
              name: '保安',
              value: 124,
              rate: '86.65'
            },
            {
              name: '塔吊指挥',
              value: 124,
              rate: '86.65'
            }
          ]
        },
        {
          top: '建材运输',
          value: 190.92,
          bottom: 'tCO2',
          pieList: [
            {
              name: '机械设备',
              value: 124,
              rate: '86.65'
            },
            {
              name: '保安',
              value: 124,
              rate: '86.65'
            },
            {
              name: '塔吊指挥',
              value: 124,
              rate: '86.65'
            }
          ]
        }
      ]
    },
    legend: {
      type: Array,
      default: () => ['钢材', '混凝土', '砂浆']
    }
  },
}
</script>

<style lang="less" scoped>
.zy-multiple-pie {
  width: 534px;
  .chart-pie {
    margin-top: 10px;
    height: 130px;
    width: 200px;
    &-inner {
      text-align: center;
      font-size: 12px;
      line-height: 20px;
    }
  }
  .legend {
    flex-wrap: wrap;
    &-item {
      margin-top: 16px;
      margin-right: 20px;
      font-size: 12px;
      color: #ffffff;
      line-height: 18px;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }
      & > div:nth-child(1) {
        width:4px;
        height: 12px;
        border-radius: 2px;
        margin-right: 6px;
      }
    }
  }
}
</style>
